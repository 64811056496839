/* eslint-disable jsx-a11y/img-redundant-alt */
// PensionCard.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PensionCard.css';

interface PensionCardProps {
  id: number;
  imageUrls: string[];
  name: string;
  rating: number;
  address: string;
  distanceKm?: number;
  description: string;
}

const PensionCard: React.FC<PensionCardProps> = ({
  id,
  imageUrls,
  name,
  rating,
  address,
  distanceKm,
  description,
}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/pensions/${id}`);
  };

  return (
    <div className="pension-card" onClick={handleCardClick} role="button" tabIndex={0}>
      <div className="pension-card-image-wrapper">
        <img src={imageUrls[0]} alt={`Image de ${name}`} className="pension-card-image" />
      </div>
      <div className="pension-card-content">
        <h2 className="pension-card-title">{name}</h2>
        <div className="pension-card-details">
          <p className="pension-card-address">{address}</p>
          {distanceKm !== undefined && distanceKm > 0 && (
            <p className="pension-card-distance">{distanceKm.toFixed(2)} km</p>
          )}
        </div>
        <div className="pension-card-rating">
          {Array.from({ length: 5 }, (_, i) => (
            <span key={i} className={`star ${i < Math.round(rating) ? 'filled' : ''}`}>
              ★
            </span>
          ))}
        </div>
        <p className="pension-card-description">{description}</p>
      </div>
    </div>
  );
};

export default PensionCard;
