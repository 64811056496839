// sendEmail.ts
const sendEmail = async ({
  toEmail,
  subject,
  htmlContent,
}: {
  toEmail: string;
  subject: string;
  htmlContent: string;
}) => {
  const API_KEY = process.env.REACT_APP_BREVO_API_KEY;

  if (!API_KEY) {
    throw new Error("La clé API Brevo n'est pas définie dans les variables d'environnement.");
  }

  const url = 'https://api.brevo.com/v3/smtp/email';

  const emailData = {
    sender: { email: 'akseelmh@gmail.com', name: 'Rintintin Booking' },
    to: [{ email: toEmail }],
    subject: subject,
    htmlContent: htmlContent,
  };

  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'api-key': API_KEY,
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(emailData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Détails de l'erreur : ", errorData);
      throw new Error(`Erreur lors de l'envoi de l'email: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Réponse réussie : ', data);
    console.log('Email envoyé avec succès', data);
  } catch (error) {
    console.error("Erreur lors de l'envoi de l'email", error);
    throw error; // Rejette l'erreur pour être capturée lors de l'appel de la fonction
  }
};

export default sendEmail;
