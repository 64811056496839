// src/components/TimePickerComponent.tsx

import React from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

interface TimePickerComponentProps {
  availableTimes: string[];
  selectedTime: string;
  onTimeSelect: (time: string) => void;
}

const TimePickerComponent: React.FC<TimePickerComponentProps> = ({
  availableTimes,
  selectedTime,
  onTimeSelect,
}) => {
  const formatTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" color="#333" sx={{ mb: 2 }}>
        Choisir une heure
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="time-select-label">Sélectionner une heure</InputLabel>
        <Select
          labelId="time-select-label"
          value={selectedTime || ''}
          label="Sélectionner une heure"
          onChange={(event) => onTimeSelect(event.target.value as string)}
          sx={{
            borderRadius: '8px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ccc',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ffcc00',
            },
            '& .MuiSvgIcon-root': {
              color: '#ffcc00',
            },
          }}
        >
          {availableTimes.map((time) => (
            <MenuItem key={time} value={time}>
              {formatTime(time)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TimePickerComponent;
