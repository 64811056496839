// src/components/DatePickerComponent.tsx

import React from 'react';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { Box } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { frFR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface DatePickerComponentProps {
  availableDates: string[];
  selectedDate: string;
  onDateSelect: (date: string) => void;
}

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  availableDates,
  selectedDate,
  onDateSelect,
}) => {
  const disableDates = (date: Dayjs) => {
    return !availableDates.includes(date.format('YYYY-MM-DD'));
  };

  return (
    <Box sx={{ mb: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={selectedDate ? dayjs(selectedDate) : null}
          onChange={(newValue: Dayjs | null) => {
            if (newValue) {
              onDateSelect(newValue.format('YYYY-MM-DD'));
            }
          }}
          shouldDisableDate={disableDates}
          localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
          disablePast
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DatePickerComponent;
