// src/pages/TrainerDetail/TrainerDetail.tsx

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import AddDogModal from '../../modals/AddDogModal';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Box,
  IconButton,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SelectChangeEvent } from '@mui/material';
import sendEmail from '../../utils/emailForm';
import { FiArrowLeft } from 'react-icons/fi';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import TimePickerComponent from '../../components/TimePickerComponent.tsx/TimePickerComponent';
import DatePickerComponent from '../../components/DatePickerComponent/DatePickerComponent';

interface Trainer {
  id: number;
  name: string;
  description: string;
  profile_photo_url: string;
  education_methods: string[];
  specialties: string[];
  photos: string[];
  email: string;
  ville: string;
  is_professional: boolean;
}

interface Dog {
  dogId: number;
  userId: number;
  name: string;
  breed: string;
  profilePhotoUrl: string;
  information: string;
  birthdate: string;
}

interface TrainerService {
  service_id: number;
  service_name: string;
  price: number;
  duration: number;
  location: string;
  question: string;
  description: string;
  hasAvailabilities?: boolean;
}

const MAX_DESCRIPTION_LENGTH = 300;

// Styled Components
const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: 0,
  minWidth: 0,
}));

const TrainerDetail: React.FC = () => {
  const navigate = useNavigate();
  const { slug } = useParams<{ slug?: string }>();
  const { isAuthenticated, user } = useAuth();

  const [trainer, setTrainer] = useState<Trainer | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [servicesTrainer, setServicesTrainer] = useState<TrainerService[]>([]);
  const [dogs, setDogs] = useState<Dog[]>([]);
  const [selectedDog, setSelectedDog] = useState<number | null>(null);
  const [isAddDogModalOpen, setIsAddDogModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<TrainerService | null>(null);
  const [clientAnswer, setClientAnswer] = useState<string>('');
  const [, setSelectedSubSlotInfo] = useState<string | null>(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState<{
    [key: number]: boolean;
  }>({});

  // États pour gérer la sélection des dates et heures par service
  const [availableDatesByService, setAvailableDatesByService] = useState<{ [key: number]: string[] }>({});
  const [selectedDateByService, setSelectedDateByService] = useState<{ [key: number]: string }>({});
  const [availableTimesByService, setAvailableTimesByService] = useState<{ [key: number]: string[] }>({});
  const [selectedTimeByService, setSelectedTimeByService] = useState<{ [key: number]: string }>({});
  const [calendarOpenForService, setCalendarOpenForService] = useState<number | null>(null);

  // Référence pour la carte de réservation
  const reservationCardRef = useRef<HTMLDivElement>(null);

  const toggleDescription = (serviceId: number) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [serviceId]: !prev[serviceId],
    }));
  };

  // Récupération des données du formateur
  useEffect(() => {
    if (slug) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/get-trainer-by-slug/${slug}`)
        .then((response) => {
          setTrainer(response.data);
          setLoading(false);

          const trainerId = response.data.id;

          // Récupération des services spécifiques à l'éducateur
          axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/get-services/${trainerId}`)
            .then(async (serviceResponse) => {
              const services: TrainerService[] = serviceResponse.data.services;

              // Pour chaque service, vérifier s'il a des disponibilités
              const servicesWithAvailability = await Promise.all(
                services.map(async (service) => {
                  try {
                    const availabilityResponse = await axios.get(
                      `${process.env.REACT_APP_API_BASE_URL}/get-available-dates/${service.service_id}`
                    );
                    return {
                      ...service,
                      hasAvailabilities: availabilityResponse.data.availableDates.length > 0,
                    };
                  } catch (error) {
                    console.error(
                      `Erreur lors de la récupération des disponibilités pour le service ${service.service_id}:`,
                      error
                    );
                    return {
                      ...service,
                      hasAvailabilities: false,
                    };
                  }
                })
              );

              setServicesTrainer(servicesWithAvailability);
            })
            .catch((error) => {
              console.error('Erreur lors de la récupération des services du formateur:', error);
            });
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération du formateur:', error);
          setLoading(false);
        });
    }
  }, [slug]);

  // Récupération des chiens de l'utilisateur authentifié
  useEffect(() => {
    if (isAuthenticated && user && user.user_id) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/get-dogs/${user.user_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          setDogs(response.data.dogs);
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des chiens dans TrainerDetail:', error);
        });
    }
  }, [isAuthenticated, user]);

  const openAddDogModal = () => {
    setIsAddDogModalOpen(true);
  };

  const closeAddDogModal = () => {
    setIsAddDogModalOpen(false);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const nextImage = () => {
    if (trainer && trainer.photos.length > 1) {
      setCurrentImageIndex((currentImageIndex + 1) % trainer.photos.length);
    }
  };

  const prevImage = () => {
    if (trainer && trainer.photos.length > 1) {
      setCurrentImageIndex((currentImageIndex - 1 + trainer.photos.length) % trainer.photos.length);
    }
  };

  const handleViewSlots = async (service: TrainerService) => {
    setCalendarOpenForService(service.service_id);
    setSelectedService(service);

    // Réinitialiser les sélections précédentes pour ce service
    setSelectedDateByService((prev) => ({ ...prev, [service.service_id]: '' }));
    setAvailableTimesByService((prev) => ({ ...prev, [service.service_id]: [] }));
    setSelectedTimeByService((prev) => ({ ...prev, [service.service_id]: '' }));

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-available-dates/${service.service_id}`
      );
      setAvailableDatesByService((prev) => ({ ...prev, [service.service_id]: response.data.availableDates }));
    } catch (error) {
      console.error('Erreur lors de la récupération des dates disponibles:', error);
      toast.error('Erreur lors de la récupération des dates disponibles.');
    }
  };

  const handleDateSelect = async (date: string, serviceId: number) => {
    setSelectedDateByService((prev) => ({ ...prev, [serviceId]: date }));
    setAvailableTimesByService((prev) => ({ ...prev, [serviceId]: [] }));
    setSelectedTimeByService((prev) => ({ ...prev, [serviceId]: '' }));

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-available-times`,
        {
          params: {
            service_id: serviceId,
            date: date,
          },
        }
      );
      setAvailableTimesByService((prev) => ({ ...prev, [serviceId]: response.data.availableTimes }));
    } catch (error) {
      console.error('Erreur lors de la récupération des créneaux horaires disponibles:', error);
      toast.error('Erreur lors de la récupération des créneaux horaires disponibles.');
    }
  };

  const handleTimeSelect = (time: string, serviceId: number) => {
    setSelectedTimeByService((prev) => ({ ...prev, [serviceId]: time }));

    if (selectedService) {
      const formattedDate = dayjs(selectedDateByService[serviceId]).locale('fr').format('dddd D MMMM');
      const formattedTime = dayjs(time, ['HH:mm:ss', 'HH:mm']).format('HH:mm');
      setSelectedSubSlotInfo(
        `Vous avez sélectionné le ${formattedDate} à ${formattedTime} pour le service ${selectedService.service_name}`
      );

      // Défilement automatique vers la carte de réservation sur mobile
      if (window.innerWidth < 600 && reservationCardRef.current) {
        reservationCardRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleDogSelect = (event: SelectChangeEvent) => {
    setSelectedDog(Number(event.target.value));
  };

  const handleReservation = async () => {
    if (selectedService) {
      const serviceId = selectedService.service_id;
      const selectedDate = selectedDateByService[serviceId];
      const selectedTime = selectedTimeByService[serviceId];

      if (selectedDate && selectedTime && selectedDog) {
        if (selectedService && selectedService.question && !clientAnswer.trim()) {
          toast.warning('Veuillez répondre à la question pour continuer.');
          return;
        }

        try {
          const reservationData = {
            client_id: user?.user_id,
            trainer_id: trainer?.id,
            service_id: selectedService?.service_id,
            date: selectedDate,
            start_time: selectedTime,
            dog_id: selectedDog,
            client_answer: clientAnswer || null,
          };

          await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-booking`, reservationData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          });

          toast.success('Réservation effectuée avec succès !');

          // Réinitialiser les sélections
          setSelectedDateByService((prev) => ({ ...prev, [serviceId]: '' }));
          setAvailableTimesByService((prev) => ({ ...prev, [serviceId]: [] }));
          setSelectedTimeByService((prev) => ({ ...prev, [serviceId]: '' }));
          setSelectedDog(null);
          setClientAnswer('');
          setSelectedSubSlotInfo(null);

          // Envoi des e-mails
          if (user && trainer) {
            // Envoi d'e-mail à l'utilisateur
            try {
              await sendEmail({
                toEmail: user.email,
                subject: 'Votre demande de réservation a été prise en compte',
                htmlContent: `
                  <p>Bonjour ${user.firstName},</p>
                  <p>Votre demande de réservation a été prise en compte. Nous reviendrons vers vous dès que possible.</p>
                  <p>Cordialement,<br>L'équipe Rintintin Booking</p>
                `,
              });
              toast.success("Un e-mail de confirmation a été envoyé.");
            } catch (error) {
              console.error("Erreur lors de l'envoi de l'e-mail à l'utilisateur:", error);
              toast.error("Erreur lors de l'envoi de l'e-mail de confirmation à l'utilisateur.");
            }

            // Envoi d'e-mail au professionnel
            try {
              await sendEmail({
                toEmail: trainer.email,
                subject: 'Nouvelle demande de réservation',
                htmlContent: `
                  <p>Bonjour ${trainer.name},</p>
                  <p>Vous avez une nouvelle demande de réservation de la part de ${user.firstName} ${user.lastName}.</p>
                  <p>Cordialement,<br>L'équipe Rintintin Booking</p>
                `,
              });
              toast.success("Un e-mail a été envoyé au professionnel.");
            } catch (error) {
              console.error("Erreur lors de l'envoi de l'e-mail au professionnel:", error);
              toast.error("Erreur lors de l'envoi de l'e-mail au professionnel.");
            }
          } else {
            console.error("Les informations de l'utilisateur ou du formateur sont manquantes.");
            toast.error("Erreur : Les informations de l'utilisateur ou du formateur sont manquantes.");
          }
        } catch (error) {
          console.error('Erreur lors de la réservation:', error);
          toast.error('Erreur lors de la réservation.');
        }
      } else {
        toast.warning('Veuillez sélectionner une date, une heure et un chien.');
      }
    } else {
      toast.warning('Veuillez sélectionner un service.');
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <Typography variant="h6">Chargement...</Typography>
      </Box>
    );
  }

  if (!trainer) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <Typography variant="h6">Éducateur non trouvé</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: { xs: '20px', md: '40px' },
        fontFamily: 'Roboto, sans-serif',
        backgroundColor: '#f9f9f9',
        minHeight: '100vh',
      }}
    >
      <Grid container spacing={4}>
        {/* Section principale */}
        <Grid item xs={12} md={8}>
          {/* Bouton Retour */}
          <IconButton
            onClick={handleBackButtonClick}
            sx={{
              mb: 2,
              color: '#ffcc00',
            }}
          >
            <FiArrowLeft size={24} />
          </IconButton>

          {/* Carousel */}
          <Box
            sx={{
              position: 'relative',
              mb: 4,
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            }}
          >
            {trainer.photos.length > 0 && (
              <img
                src={trainer.photos[currentImageIndex]}
                alt={`Trainer ${trainer.name}`}
                style={{
                  width: '100%',
                  height: '400px',
                  objectFit: 'cover',
                }}
              />
            )}
            {trainer.photos.length > 1 && (
              <>
                <IconButton
                  onClick={prevImage}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(255, 204, 0, 0.7)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 204, 0, 0.9)',
                    },
                  }}
                >
                  <FiArrowLeft size={16} />
                </IconButton>
                <IconButton
                  onClick={nextImage}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%) rotate(180deg)',
                    backgroundColor: 'rgba(255, 204, 0, 0.7)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 204, 0, 0.9)',
                    },
                  }}
                >
                  <FiArrowLeft size={16} />
                </IconButton>
              </>
            )}
          </Box>

          {/* Informations du formateur */}
          <Card
            sx={{
              mb: 4,
              p: 3,
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            <CardContent>
              <Typography variant="h4" gutterBottom>
                {trainer.name}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                📍 {trainer.ville}
              </Typography>
              <Typography variant="body1" paragraph>
                {trainer.description}
              </Typography>

              {trainer.education_methods.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">🧑‍🏫 Méthodes d'éducation</Typography>
                  <ul>
                    {trainer.education_methods.map((method, index) => (
                      <li key={index}>
                        <Typography variant="body2">{method}</Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
              )}

              {trainer.specialties.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">💼 Spécialités</Typography>
                  <ul>
                    {trainer.specialties.map((specialty, index) => (
                      <li key={index}>
                        <Typography variant="body2">{specialty}</Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
              )}
            </CardContent>
          </Card>

          {/* Liste des services */}
          {trainer.is_professional && (
            <Grid container spacing={3}>
              {servicesTrainer.map((service) => (
                <Grid item xs={12} key={service.service_id}>
                  <Card
                    sx={{
                      p: 3,
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  >
                    <CardContent>
                      <Typography variant="h5" gutterBottom>
                        {service.service_name} ({service.price}€ - {service.duration} min) à {service.location}
                      </Typography>

                      {/* Description avec "Voir plus / Voir moins" */}
                      <Typography variant="body2" color="text.secondary">
                        {service.description.length > MAX_DESCRIPTION_LENGTH && !expandedDescriptions[service.service_id]
                          ? (
                            <>
                              {`${service.description.substring(0, MAX_DESCRIPTION_LENGTH)}... `}
                              <StyledButton
                                variant="text"
                                onClick={() => toggleDescription(service.service_id)}
                                sx={{
                                  color: '#ffcc00',
                                  fontWeight: 'bold',
                                  textTransform: 'none',
                                }}
                              >
                                Voir plus
                              </StyledButton>
                            </>
                          )
                          : (
                            <>
                              {service.description}{' '}
                              {service.description.length > MAX_DESCRIPTION_LENGTH && (
                                <StyledButton
                                  variant="text"
                                  onClick={() => toggleDescription(service.service_id)}
                                  sx={{
                                    color: '#ffcc00',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                  }}
                                >
                                  Voir moins
                                </StyledButton>
                              )}
                            </>
                          )
                        }
                      </Typography>

                      {/* Bouton "Voir disponibilités" */}
                      {service.hasAvailabilities ? (
                        <StyledButton
                          variant="contained"
                          onClick={() => handleViewSlots(service)}
                          sx={{
                            backgroundColor: '#333',
                            color: '#fff',
                            '&:hover': {
                              backgroundColor: '#555',
                            },
                            mt: 2,
                            padding: '4px 10px',  // Ajout de padding pour agrandir le bouton
                            fontSize: '1rem',       // Ajustement de la taille de la police
                            minWidth: '200px', 
                          }}
                        >
                          Voir les disponibilités
                        </StyledButton>
                      ) : (
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                          Pas de disponibilités pour le service actuellement
                        </Typography>
                      )}

                      {/* Affichage du calendrier si le bouton a été cliqué */}
                      {calendarOpenForService === service.service_id && (
                        <Box sx={{ mt: 3 }}>
                          <DatePickerComponent
                            availableDates={availableDatesByService[service.service_id] || []}
                            selectedDate={selectedDateByService[service.service_id] || ''}
                            onDateSelect={(date) => handleDateSelect(date, service.service_id)}
                          />

                          {selectedDateByService[service.service_id] && (
                            <TimePickerComponent
                              availableTimes={availableTimesByService[service.service_id] || []}
                              selectedTime={selectedTimeByService[service.service_id] || ''}
                              onTimeSelect={(time) => handleTimeSelect(time, service.service_id)}
                            />
                          )}
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>

        {/* Carte de réservation */}
<Grid item xs={12} md={4}>
  <Card
    ref={reservationCardRef}
    sx={{
      border: '2px solid #ffcc00',
      backgroundColor: '#fafafa',
      borderRadius: '20px',
      p: 3,
      boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
      position: { md: 'sticky' },
      top: { md: '80px' },
    }}
  >
    <CardContent>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ color: '#333', fontSize: '1.6rem', fontWeight: 'bold' }}
      >
        Réserver un service
      </Typography>

      {!isAuthenticated ? (
        <Box>
          <Typography variant="body1" gutterBottom sx={{ color: '#555' }}>
            Vous devez être connecté pour réserver un service.
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate('/login')}
            sx={{
              mt: 2,
              backgroundColor: '#ffcc00',
              textTransform: 'none', // Texte non transformé en majuscules
              color: '#333',
              '&:hover': {
                backgroundColor: '#e0b127',
              },
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <i className="fas fa-sign-in-alt" style={{ marginRight: '8px' }}></i>
            Se connecter
          </Button>
        </Box>
      ) : (
        <Box>
          {selectedService && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" sx={{ color: '#555' }}>
                <strong>Service :</strong> {selectedService.service_name}
              </Typography>
              {selectedDateByService[selectedService.service_id] && (
                <Typography variant="body1" sx={{ color: '#555' }}>
                  <strong>Date :</strong> {dayjs(selectedDateByService[selectedService.service_id]).locale('fr').format('dddd D MMMM')}
                </Typography>
              )}
              {selectedTimeByService[selectedService.service_id] && (
                <Typography variant="body1" sx={{ color: '#555' }}>
                  <strong>Heure :</strong> {dayjs(selectedTimeByService[selectedService.service_id], ['HH:mm:ss', 'HH:mm']).format('HH:mm')}
                </Typography>
              )}
            </Box>
          )}

          {!selectedService && (
            <Typography variant="body1" sx={{ color: '#777' }}>
              Veuillez sélectionner un service pour continuer.
            </Typography>
          )}

          {/* Ajouter un chien */}

            {dogs.length > 0 ? (
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="dog-select-label">Choisir un chien</InputLabel>
                <Select
                  labelId="dog-select-label"
                  value={selectedDog !== null ? selectedDog.toString() : ''}
                  onChange={handleDogSelect}
                  label="Choisir un chien"
                >
                  {dogs.map((dog) => (
                    <MenuItem key={dog.dogId} value={dog.dogId.toString()}>
                      {dog.name} - {dog.breed}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2, color: '#999' }}>
                Vous n'avez pas encore ajouté de chien à votre compte.
              </Typography>
            )}
<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

<Button
  variant="contained"
  onClick={openAddDogModal}
  sx={{
    backgroundColor: '#333',
    color: '#fff',
    textTransform: 'none', // Texte non transformé en majuscules
    marginBottom: 2, // Espace entre le bouton et l'élément en dessous
    '&:hover': {
      backgroundColor: '#555',
    },
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }}
>
  <i className="fas fa-plus-circle" style={{ marginRight: '8px' }}></i>
  Ajouter un chien
</Button>

          </Box>

          {/* Question du service */}
          {selectedService && selectedService.question && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="body1" gutterBottom sx={{ color: '#555' }}>
                {selectedService.question}
              </Typography>
              <TextField
                label="Votre réponse"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={clientAnswer}
                onChange={(e) => setClientAnswer(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ddd',
                    },
                    '&:hover fieldset': {
                      borderColor: '#ffcc00',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#ffcc00',
                    },
                  },
                }}
              />
            </Box>
          )}

          {/* Bouton Réserver */}
          <StyledButton
            variant="contained"
            onClick={handleReservation}
            disabled={
              !selectedService ||
              !selectedDateByService[selectedService.service_id] ||
              !selectedTimeByService[selectedService.service_id] ||
              !selectedDog
            }
            sx={{
              mt: 3,
              backgroundColor: '#ffcc00',
              color: '#333',
              '&:hover': {
                backgroundColor: '#e0b127',
              },
              width: '100%',
              boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
              fontWeight: 'bold',
              padding: '12px 0',
            }}
          >
            <i className="fas fa-calendar-check" style={{ marginRight: '8px' }}></i>
            Réserver
          </StyledButton>
        </Box>
      )}
    </CardContent>
  </Card>
</Grid>

      </Grid>

      {/* Modal pour ajouter un chien */}
      <AddDogModal
        isOpen={isAddDogModalOpen}
        onRequestClose={closeAddDogModal}
        onDogAdded={(newDog) => {
          setDogs([...dogs, newDog]);
          closeAddDogModal();
        }}
      />

      {/* Conteneur des notifications */}
      <ToastContainer />
    </Box>
  );
};

export default TrainerDetail;
