// GeneralSearch.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PensionCard from '../../components/PensionCard/PensionCard';
import TrainerCard from '../../components/TrainerCard/TrainerCard';
import './GeneralSearch.css';

interface Pension {
  id: number;
  name: string;
  address: string;
  rating: number;
  description: string;
  imageUrls: string[];
}

interface Trainer {
  id: number;
  slug: string;
  name: string;
  description: string;
  profile_photo_url: string;
  ville: string;
}

const GeneralSearch: React.FC = () => {
  const [pensions, setPensions] = useState<Pension[]>([]);
  const [trainers, setTrainers] = useState<Trainer[]>([]);
  const navigate = useNavigate();

  // Fetch pensions and trainers
  useEffect(() => {
    fetchPensions();
    fetchTrainers();
  }, []);

  const fetchPensions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-pensions`, {
        params: { limit: 4 },
      });
      setPensions(response.data.pensions);
    } catch (error) {
      console.error('Error fetching pensions:', error);
    }
  };

  const fetchTrainers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-trainers`, {
        params: { limit: 4 },
      });
      setTrainers(response.data.trainers);
    } catch (error) {
      console.error('Error fetching trainers:', error);
    }
  };

  const handleSeeMorePensions = () => {
    navigate('/pensions');
  };

  const handleSeeMoreTrainers = () => {
    navigate('/trainers');
  };

  return (
    <div className="general-search-container">
      {/* Section Pensions */}
      <section className="section-container">
        <h2 className="section-title">Nos pensions</h2>
        <div className="cards-container">
          {pensions.map((pension) => (
            <PensionCard
              key={pension.id}
              id={pension.id}
              imageUrls={pension.imageUrls}
              name={pension.name}
              rating={pension.rating}
              address={pension.address}
              description={pension.description}
            />
          ))}
        </div>
        <div className="see-more-btn-container">
          <button className="see-more-btn" onClick={handleSeeMorePensions}>
            Voir plus de pensions
          </button>
        </div>
      </section>

      {/* Section Trainers */}
      <section className="section-container">
        <h2 className="section-title">Nos éducateurs canins</h2>
        <div className="cards-container">
          {trainers.map((trainer) => (
            <TrainerCard
              key={trainer.id}
              slug={trainer.slug}
              profilePhotoUrl={trainer.profile_photo_url}
              name={trainer.name}
              ville={trainer.ville}
              description={trainer.description}
            />
          ))}
        </div>
        <div className="see-more-btn-container">
          <button className="see-more-btn" onClick={handleSeeMoreTrainers}>
            Voir plus d'éducateurs
          </button>
        </div>
      </section>
    </div>
  );
};

export default GeneralSearch;
