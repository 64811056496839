import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import { ClipLoader } from 'react-spinners';
import './TrainerList.css';
import TrainerCard from '../../components/TrainerCard/TrainerCard';

interface Trainer {
  id: number;
  slug: string;
  name: string;
  description: string;
  profile_photo_url: string;
  ville: string;
  rating: number;
  education_methods: string[];
  specialties: string[];
  services: string[];
}

const TrainerList: React.FC = () => {
  const location = useLocation();
  const { address } = location.state || {}; // Removed 'coordinates' as it was unused
  const [trainers, setTrainers] = useState<Trainer[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTrainers(address);
  }, [address]);
  
  const fetchTrainers = async (address?: string) => {
    try {
      setLoading(true);
      const params: any = {};
      if (address) params.address = address;
  
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-trainers`, { params });
      console.log(response.data.trainers); // Ajoutez ceci pour vérifier les données
      const fetchedTrainers: Trainer[] = response.data.trainers;
      setTrainers(fetchedTrainers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching trainers:', error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchTrainers(address);
  }, [address]);

  const handleSearch = (address: string) => {
    fetchTrainers(address);
  };

  return (
    <div className="trainer-list-container">
      <SearchBar withMarginTop={false} onSearch={handleSearch} />
      <div className="content-container">
        <div className="trainer-cards">
          {loading ? (
            <ClipLoader size={50} color={"#e0b127"} loading={loading} /> 
          ) : (
            trainers.map(trainer => (
              <TrainerCard
                key={trainer.id}
                slug={trainer.slug}  // Utilisez le slug
                profilePhotoUrl={trainer.profile_photo_url}
                name={trainer.name}
                ville={trainer.ville} 
                description={trainer.description}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainerList;
