// ReservationList.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ReservationList.css';
import { format, parseISO, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Link } from 'react-router-dom';

interface Dog {
  dogId: number;
  name: string;
  breed: string;
}

interface Service {
  serviceId: number;
  serviceName: string;
  location: string;
  question: string;
}

interface Trainer {
  trainerId: number;
  name: string;
  slug: string;
}

interface Booking {
  bookingId: number;
  status: string;
  dateCreated: string;
  clientAnswer: string;
  startTime: string;
  endTime: string;
  date: string;
  dog: Dog;
  service: Service;
  trainer: Trainer;
}

interface ReservationListProps {
  userId: number;
}

const ReservationList: React.FC<ReservationListProps> = ({ userId }) => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/get-bookings-user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        setBookings(response.data.bookings);
      } catch (error) {
        console.error('Erreur lors de la récupération des réservations :', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [userId]);

  if (loading) {
    return <p>Chargement des réservations...</p>;
  }

  if (bookings.length === 0) {
    return <p>Vous n'avez aucune réservation.</p>;
  }

  // Traiter les réservations
  const today = new Date();

  // Filtrer les réservations à venir et passées
  const upcomingBookings = bookings.filter(
    (booking) => parseISO(booking.date) >= today
  );

  const pastBookings = bookings.filter(
    (booking) => parseISO(booking.date) < today
  );

  // Grouper les réservations à venir par statut
  const validatedBookings = upcomingBookings
    .filter((booking) => booking.status === 'validated')
    .sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());

  const pendingBookings = upcomingBookings
    .filter((booking) => booking.status === 'pending')
    .sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());

  const declinedBookings = upcomingBookings
    .filter((booking) => booking.status === 'declined')
    .sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());

  const formatDate = (dateString: string) => {
    const date = parseISO(dateString);
    return format(date, 'EEEE d MMMM', { locale: fr });
  };

  const formatTime = (timeString: string) => {
    const time = parse(timeString, 'HH:mm:ss', new Date());
    return format(time, 'HH:mm');
  };

  return (
    <div className="reservation-list">
      {validatedBookings.length > 0 && (
        <div className="booking-section">
          <h2>Réservations à venir</h2>
          {validatedBookings.map((booking) => (
            <div key={booking.bookingId} className={`reservation-card ${booking.status}`}>
              <div className="reservation-card-header">
                <h3>{booking.service.serviceName}</h3>
                <span className={`status-badge ${booking.status}`}>
                  Validée
                </span>
              </div>
              <div className="reservation-card-body">
                <p>
                  <strong>Date :</strong> {formatDate(booking.date)} de {formatTime(booking.startTime)} à {formatTime(booking.endTime)}
                </p>
                <p>
                  <strong>Professionnel :</strong>{' '}
                  <Link to={`/pro/${booking.trainer.slug}`}>
                    {booking.trainer.name}
                  </Link>
                </p>
                <p>
                  <strong>Lieu :</strong> {booking.service.location}
                </p>
                <p>
                  <strong>Chien :</strong> {booking.dog.name} ({booking.dog.breed})
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      {pendingBookings.length > 0 && (
        <div className="booking-section">
          <h2>Réservations en attente de validation</h2>
          {pendingBookings.map((booking) => (
            <div key={booking.bookingId} className={`reservation-card ${booking.status}`}>
              <div className="reservation-card-header">
                <h3>{booking.service.serviceName}</h3>
                <span className={`status-badge ${booking.status}`}>
                  En attente de validation
                </span>
              </div>
              <div className="reservation-card-body">
                <p>
                  <strong>Date :</strong> {formatDate(booking.date)} de {formatTime(booking.startTime)} à {formatTime(booking.endTime)}
                </p>
                <p>
                  <strong>Professionnel :</strong>{' '}
                  <Link to={`/pro/${booking.trainer.slug}`}>
                    {booking.trainer.name}
                  </Link>
                </p>
                <p>
                  <strong>Lieu :</strong> {booking.service.location}
                </p>
                <p>
                  <strong>Chien :</strong> {booking.dog.name} ({booking.dog.breed})
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      {declinedBookings.length > 0 && (
        <div className="booking-section">
          <h2>Réservations refusées</h2>
          {declinedBookings.map((booking) => (
            <div key={booking.bookingId} className={`reservation-card ${booking.status}`}>
              <div className="reservation-card-header">
                <h3>{booking.service.serviceName}</h3>
                <span className={`status-badge ${booking.status}`}>
                  Refusée
                </span>
              </div>
              <div className="reservation-card-body">
                <p>
                  <strong>Date :</strong> {formatDate(booking.date)} de {formatTime(booking.startTime)} à {formatTime(booking.endTime)}
                </p>
                <p>
                  <strong>Professionnel :</strong>{' '}
                  <Link to={`/pro/${booking.trainer.slug}`}>
                    {booking.trainer.name}
                  </Link>
                </p>
                <p>
                  <strong>Lieu :</strong> {booking.service.location}
                </p>
                <p>
                  <strong>Chien :</strong> {booking.dog.name} ({booking.dog.breed})
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      {pastBookings.length > 0 && (
        <div className="booking-section">
          <h2>Réservations passées</h2>
          {pastBookings.map((booking) => (
            <div key={booking.bookingId} className="reservation-card past">
              <div className="reservation-card-header">
                <h3>{booking.service.serviceName}</h3>
                <span className={`status-badge ${booking.status}`}>
                  {booking.status === 'validated' && 'Validée'}
                  {booking.status === 'pending' && 'En attente'}
                  {booking.status === 'declined' && 'Refusée'}
                </span>
              </div>
              <div className="reservation-card-body">
                <p>
                  <strong>Date :</strong> {formatDate(booking.date)} de {formatTime(booking.startTime)} à {formatTime(booking.endTime)}
                </p>
                <p>
                  <strong>Professionnel :</strong>{' '}
                  <Link to={`/pro/${booking.trainer.slug}`}>
                    {booking.trainer.name}
                  </Link>
                </p>
                <p>
                  <strong>Lieu :</strong> {booking.service.location}
                </p>
                <p>
                  <strong>Chien :</strong> {booking.dog.name} ({booking.dog.breed})
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReservationList;
