// ReservationsPage.tsx

import React from 'react';
import ReservationList from '../../components/ReservationList/ReservationList';
import { useAuth } from '../../context/AuthContext';
import './ReservationsPage.css';

const ReservationsPage: React.FC = () => {
  const { user } = useAuth();

  return (
    <div className="reservations-page-container">
      <div className="reservations-header">
      </div>
      <div className="reservations-content">
        {user ? (
          <ReservationList userId={user.user_id} />
        ) : (
          <p>Vous devez être connecté pour voir vos réservations.</p>
        )}
      </div>
    </div>
  );
};

export default ReservationsPage;
